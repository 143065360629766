<template>
    <q-dialog
        v-model="showConfirmDialog"
        persistent
        position="top"
        transitionShow="scale"
        transitionHide="scale"
        @show="dialogShowed"
        @hide="dialogHided"
        @before-show="beforeShow"
    >
        <q-card
            v-if="currentQuestion < questions.length"
            class="test-dialog"
        >
            <q-card-section>
                <div class="text-body2">
                    Прослушайте фрагмент и ответьте на вопрос
                </div>
                <div
                    class="text-h6 test-dialog--question"
                    v-html="questions[currentQuestion].question"
                />
            </q-card-section>

            <audio
                id="aplayer"
                :src="audioSrc"
                type="audio/mpeg"
                preload="auto"
                @canplay="canplay"
            >
                Your browser does not support the <code>audio</code> element.
            </audio>

            <q-btn
                flat
                label="Проиграть звуковой фрагмент"
                icon="fas fa-play"
                color="primary"
                :disabled="!bCanPlay"
                @click="startPlay"
            />

            <q-linear-progress :value="currentQuestion / questions.length" />

            <q-card-section class="answers-section">
                <!--
                <q-option-group
                    v-if="questions[currentQuestion].kind == 'radio'"
                    v-model="currentAnswer"
                    :options="answers"
                    color="primary"
                />
                -->
                <q-list v-if="questions[currentQuestion].kind == 'radio'">
                    <q-item
                        v-for="(answer, index) in answers"
                        :key="index"
                        v-ripple
                        tag="label"
                        dense
                    >
                        <q-item-section
                            avatar
                            top
                        >
                            <q-radio
                                v-model="currentAnswer"
                                :val="answer.value"
                            />
                        </q-item-section>
                        <q-item-section>
                            {{ answer.label }}
                        </q-item-section>
                    </q-item>
                </q-list>

                <q-input
                    v-else-if="questions[currentQuestion].kind == 'text'"
                    v-model="currentAnswer"
                    outlined
                    dense
                />
            </q-card-section>

            <q-separator />

            <q-card-actions>
                <q-btn
                    v-close-popup
                    flat
                    label="Выйти"
                    icon="far fa-times-circle"
                    color="primary"
                />
                <q-space />
                <q-btn
                    flat
                    :label="currentQuestion < questions.length - 1 ? 'Дальше' : 'Закончить тест'"
                    icon="fas fa-chevron-right"
                    color="primary"
                    :disabled="!currentAnswer"
                    @click="doAnswer"
                />
            </q-card-actions>
        </q-card>
        <q-card
            v-else
            class="test-dialog"
        >
            <q-card-section>
                <!--
                {{ questions.length }}
                {{ currentQuestion }}
                {{ currentAnswer }}
                {{ rightAnswers }}
                -->
                <div class="text-h6">
                    Ваш результат
                </div>
                <div style="text-align: center;">
                    {{ rightAnswers }} из {{ questions.length }}
                </div>
                <div v-if="rightAnswers > questions.length * 0.75">
                    Отличный результат, рекомендуем для поддержки высокого
                    уровня регулярно заниматься с English Puzzle
                </div>
                <div v-else-if="rightAnswers > questions.length * 0.5">
                    Хороший результат, рекомендуем для повышения
                    уровня регулярно заниматься с English Puzzle
                </div>
                <div v-else>
                    Не очень хороший результат, рекомендуем для повышения
                    уровня регулярно заниматься с English Puzzle
                </div>
            </q-card-section>
            <q-card-actions>
                <q-btn
                    v-close-popup
                    flat
                    label="Выйти"
                    icon="far fa-times-circle"
                    color="primary"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import axios from "axios";
import Stat from "../../../ep-online/src/helpers/stat";

export default {
    name: "DialogRunTest",
    props: {
        showDialog: {
            type: Number,
            required: true,
        },
        sName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showConfirmDialog: false,
            questions: [
                {
                    question: "mock",
                    type: "none",
                    rightAnswer: "mock",
                    media: "",
                },
            ],
            currentQuestion: 0,
            answers: [],
            currentAnswer: null,
            rightAnswers: 0,
            timerId: null,
            aplayer: null,
            timeExtra: 1.5,
            timeStarted: 0,
            startPlayWasSet: false,
            audioSrc: "",

            timeStart: 20,
            timeFinsh: 25,
            bCanPlay: false,
        };
    },
    watch: {
        showDialog() {
            this.currentQuestion = 0;
            this.currentAnswer = null;
            this.rightAnswers = 0;
            this.showConfirmDialog = true;
        },
    },
    methods: {
        prepareQuestion() {
            this.answers = this.questions[this.currentQuestion].answers
                .map((answer, index) => ({
                    value: index + 1,
                    label: answer.answer,
                }));

            // shuffle answers
            for (let i = this.answers.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [this.answers[i], this.answers[j]] = [this.answers[j], this.answers[i]];
            }

            if (this.audioSrc !== this.questions[this.currentQuestion].media) {
                this.bCanPlay = false;
                this.audioSrc = this.questions[this.currentQuestion].media;
            }
            this.timeStart = Math.max(
                0,
                this.questions[this.currentQuestion].timeStart - this.timeExtra,
            );
            this.timeFinsh = this.questions[this.currentQuestion].timeFinsh;
            this.currentAnswer = null;
        },
        beforeShow() {
            const { href } = window.location;
            const epUrl = href.includes("localhost")
                ? "http://localhost:8888/eprest/test-questions"
                : "/eprest/test-questions";
            axios.get(epUrl)
                .then((res) => {
                    // console.log(res.data);
                    this.questions = res.data.map((q) => ({
                        ...q,
                        question: q.question
                            .replace(/#*#/g, "'")
                            .replace(/\n/g, "<br>"),
                    }));
                    this.prepareQuestion();
                })
                .catch((err) => {
                    console.log(err); // eslint-disable-line
                });

            Stat.sendStat("test-start");
        },
        dialogShowed() {
            this.aplayer = document.getElementById("aplayer");
            this.timerId = setTimeout(this.timeLoop, 200);
        },
        dialogHided() {
            clearTimeout(this.timerId);
        },
        doAnswer() {
            this.aplayer.pause();
            let result = false;

            if (this.questions[this.currentQuestion].kind === "radio") {
                result =
                    (+this.questions[this.currentQuestion].rightAnswer ===
                        +this.currentAnswer);
            }
            else {
                result =
                    this.questions[this.currentQuestion].rightAnswer
                        .toLowerCase()
                        .trim() ===
                    this.currentAnswer
                        .toLowerCase()
                        .trim();
            }

            this.rightAnswers += result
                ? 1
                : 0;

            const { iidnt } = this.questions[this.currentQuestion];
            Stat.sendStat(`test-answer-${this.currentQuestion}-${iidnt}-${result ? 1 : 0}`);

            this.currentQuestion++;

            if (this.currentQuestion < this.questions.length) {
                this.prepareQuestion();
            }
        },
        canplay() {
            this.bCanPlay = true;
        },
        setPlayerVolume(volume) {
            this.aplayer.volume = volume;
        },
        startPlay() {
            this.timeStarted = this.timeStart;
            this.aplayer.currentTime = this.timeStart;
            this.setPlayerVolume(0);
            this.aplayer.play();
            this.startPlayWasSet = true;
        },
        timeLoop() {
            this.timerId = setTimeout(this.timeLoop, 200);

            if (!this.aplayer || !this.startPlayWasSet) {
                return;
            }

            const timePlayed = this.aplayer.currentTime - this.timeStarted;
            const timeRemain = this.timeFinsh - this.aplayer.currentTime;

            if (timePlayed < this.timeExtra) {
                this.setPlayerVolume(timePlayed / this.timeExtra < 0
                    ? 0
                    : timePlayed / this.timeExtra);
            }
            else if (timeRemain < this.timeExtra) {
                this.setPlayerVolume(timeRemain / this.timeExtra < 0
                    ? 0
                    : timeRemain / this.timeExtra);
            }
            else {
                this.setPlayerVolume(1);
            }

            if (this.aplayer.currentTime >= this.timeFinsh || this.aplayer.ended) {
                if (!this.aplayer.paused) {
                    this.aplayer.pause();
                    this.startPlayWasSet = false;
                }
            }
        },
    },
};
</script>
