<template>
    <q-dialog
        v-model="showConfirmDialog"
        persistent
        position="top"
        transitionShow="scale"
        transitionHide="scale"
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    {{ `Delete note ${iNoteId} of ${sName}` }}
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Cancel"
                    icon="far fa-times-circle"
                    color="primary"
                />
                <q-btn
                    flat
                    label="Delete"
                    icon="fas fa-trash-alt"
                    color="primary"
                    @click="deleteNote"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import axios from "axios";

export default {
    name: "DialogConfirm",
    props: {
        showDialog: {
            type: Number,
            required: true,
        },
        sName: {
            type: String,
            required: true,
        },
        iNoteId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showConfirmDialog: false,
        };
    },
    watch: {
        showDialog() {
            this.showConfirmDialog = true;
        },
    },
    methods: {
        deleteNote() {
            axios.delete(`/notes/${this.iNoteId}`)
                .then(() => {
                    this.$q.notify({
                        message: "Note was deleted",
                        color: "green",
                    });
                    this.showConfirmDialog = false;

                    if (window.location.pathname.substr(0, 6) === "/notes") {
                        window.location.assign("/notes");
                    }
                })
                .catch(() => {
                    // TODO: show appropriate message
                    this.$q.notify({
                        message: "ERROR",
                        color: "red",
                    });
                });
        },
    },
};
</script>
