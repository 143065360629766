import axios from "axios";

export default {
    formatDatePart(value) {
        return value < 10 ? `0${value}` : `${value}`;
    },

    getGuestId() {
        // define user ID
        let epGuest = localStorage.getItem("enpuzguest");

        if (!epGuest || epGuest.length !== 17) {
            // try to get from cookie
            // enpuzguest=210528113956_7608
            [epGuest] = decodeURIComponent(document.cookie)
                .split("; ")
                .filter((c) => c.startsWith("enpuzguest="));

            if (epGuest && epGuest.length === 28) {
                epGuest = epGuest.substring(11);
                localStorage.setItem("enpuzguest", epGuest);
            }
            else {
                epGuest = null;
            }
        }

        if (!epGuest) {
            const date = new Date();
            epGuest = [
                date.getFullYear().toString().substring(2),
                this.formatDatePart(date.getMonth() + 1),
                this.formatDatePart(date.getDate()),
                this.formatDatePart(date.getHours()),
                this.formatDatePart(date.getMinutes()),
                this.formatDatePart(date.getSeconds()),
                "_",
                Math.floor(Math.random() * 9000 + 1000),
            ].join("");

            localStorage.setItem("enpuzguest", epGuest);
            document.cookie = `enpuzguest=${epGuest}; expires=Mon, 17 Dec 2040 12:00:00 UTC; path=/`;
        }

        return epGuest;
    },

    sendStat(pageUrl) {
        const epGuest = this.getGuestId();
        const { href } = window.location;
        const epUrl = href.includes("localhost")
            ? "http://localhost:8888/eprest/stat"
            : "/eprest/stat";

        axios.post(
            epUrl,
            {
                epGuest,
                pathname: pageUrl || window.location.pathname,
                screen: `${window.innerWidth}x${window.innerHeight}`,
                // TODO: document.referrer doesn't work with vue router in my case
                // try to fix it
                referrer: document.referrer,
            },
        );

        /*
        TODO: consider if it is needed (google analytics)
        setTimeout(() => {
            if (process.env.NODE_ENV !== "development" &&
                window.location.hostname !== "localhost" &&
                !(
                    component.user &&
                    component.user.isLoggedIn &&
                    component.user.displayName === "SibMan"
                )) {
                component.$gtag.pageview(window.location.pathname);
            }
        }, 2000);
        */
    },
};
