<template>
    <q-dialog
        v-model="showMessageDialog"
        persistent
        position="top"
        transitionShow="scale"
        transitionHide="scale"
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    {{ sName ? `Answer to ${sName}` : 'New question' }}
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 50vh"
                class="scroll q-gutter-md"
            >
                <q-input
                    ref="wrtrNameRef"
                    v-model="wrtrName"
                    hideBottomSpace
                    color="primary"
                    outlined
                    label="Your Name"
                    lazyRules
                    :rules="[val => val && val.length >= 3 || 'Type at least 3 chars']"
                >
                    <template #prepend>
                        <q-icon :name="matPerson" />
                    </template>
                </q-input>
                <q-input
                    v-model="wrtrMail"
                    color="primary"
                    outlined
                    label="Email"
                >
                    <template #prepend>
                        <q-icon :name="matAlternateEmail" />
                    </template>
                </q-input>
                <q-input
                    ref="question"
                    v-model="wrtrQstn"
                    hideBottomSpace
                    color="primary"
                    outlined
                    autogrow
                    label="Question or message"
                    lazyRules
                    :rules="[val => val && val.length >= 10 || 'Type at least 10 chars']"
                >
                    <template #prepend>
                        <q-icon name="far fa-question-circle" />
                    </template>
                </q-input>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Cancel"
                    icon="far fa-times-circle"
                    color="primary"
                />
                <q-btn
                    flat
                    label="Send"
                    icon="fas fa-paper-plane"
                    color="primary"
                    @click="sendMessageB"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import axios from "axios";

export default {
    name: "DialogMessage",
    props: {
        showDialog: {
            type: Number,
            required: true,
        },
        sName: {
            type: String,
            required: true,
        },
        iRoot: {
            type: Number,
            required: true,
        },
        iPrnt: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showMessageDialog: false,
            wrtrName: "",
            wrtrMail: "",
            wrtrQstn: "",
            userId: "",
        };
    },
    watch: {
        showDialog() {
            this.showMessageDialog = true;
            this.wrtrName = "";
            this.wrtrMail = "";
            this.wrtrQstn = "";
        },
    },
    methods: {
        sendMessageB() {
            this.$refs.wrtrNameRef.validate();
            this.$refs.question.validate();
            if (this.$refs.wrtrNameRef.hasError || this.$refs.question.hasError) {
                return;
            }

            const url = "/notes";
            const msg = {
                root: this.iRoot,
                prnt: this.iPrnt,
                user: this.userId,
                wrtr: this.wrtrName,
                mail: this.wrtrMail,
                body: this.wrtrQstn,
            };

            // TODO: investigate how to send raw json object
            axios.post(
                url,
                `message=${JSON.stringify(msg)}`,
            )
                .then(() => {
                    this.$q.notify({
                        message: "Сообщение отправлено",
                        color: "green",
                    });
                    this.showMessageDialog = false;

                    if (window.location.pathname.substr(0, 6) === "/notes") {
                        window.location.assign("/notes");
                    }
                })
                .catch(() => {
                    // TODO: show appropriate message
                    this.$q.notify({
                        message: "ERROR",
                        color: "red",
                    });
                });
        },
    },
};
</script>
