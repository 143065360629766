<template>
    <div>
        <DialogMessage
            ref="dialogMessage"
            :showDialog="showMessageDialogSign"
            :sName="sName"
            :iRoot="iRoot"
            :iPrnt="iPrnt"
        />
        <DialogConfirm
            ref="dialogConfirm"
            :showDialog="showConfirmDialogSign"
            :sName="sName"
            :iNoteId="iNoteId"
        />
        <DialogRunTest
            ref="dialogRunTest"
            :showDialog="showRunTestDialogSign"
            :sName="sName"
        />
        <SideMenu
            ref="sideMenu"
            :showMenu="showSideMenuSign"
            :context="sideMenuContext"
        />
    </div>
</template>

<script>
import axios from "axios";

import DialogMessage from "./components/DialogMessage.vue";
import DialogConfirm from "./components/DialogConfirm.vue";
import DialogRunTest from "./components/DialogRunTest.vue";
import SideMenu from "./components/SideMenu.vue";

import Stat from "../../ep-online/src/helpers/stat";

export default {
    name: "EpDialogs",
    components: {
        DialogMessage,
        DialogConfirm,
        DialogRunTest,
        SideMenu,
    },
    data() {
        return {
            // common parameter
            sName: "",

            // for message dialog
            showMessageDialogSign: 0,
            iRoot: 0,
            iPrnt: 0,

            // for confirm dialog (delete note)
            showConfirmDialogSign: 0,
            iNoteId: 0,

            // for test
            showRunTestDialogSign: 0,

            // for menu
            sideMenuContext: [],
            showSideMenuSign: 0,

            // for statistic
            // epGuest: "",
            // epUrl: "",
        };
    },
    created() {
        Stat.sendStat();

        window.showQuestionDialog = this.showQuestionDialog;
        window.showConfirmDialog = this.showConfirmDialog;
        window.showRunTestDialog = this.showRunTestDialog;
        window.showSideMenu = this.showSideMenu;
    },
    errorCaptured(err, vm, info) {
        const statInfo = JSON.stringify({
            epGuest: "GUEST_ERROR_2",
            pathname: info,
            screen: "11x22",
            referrer: err.toString(),
        });
        axios.post(
            "/eprest/stat",
            `statInfo=${statInfo}`,
        );
    },
    methods: {
        showQuestionDialog(context) {
            this.sName = context && context.sname ? context.sname : "";
            this.iRoot = context && context.iroot ? context.iroot : 0;
            this.iPrnt = context && context.iprnt ? context.iprnt : 0;

            this.showMessageDialogSign = Date.now();
        },
        showConfirmDialog(context) {
            this.sName = context && context.sname ? context.sname : "";
            this.iNoteId = context && context.noteId ? context.noteId : 0;
            this.showConfirmDialogSign = Date.now();
        },
        showRunTestDialog(context) {
            this.sName = context && context.sname ? context.sname : "";
            this.showRunTestDialogSign = Date.now();
        },
        showSideMenu(context) {
            this.sideMenuContext = context;
            this.showSideMenuSign = Date.now();
        },
        /*
        beforeUnload() {
            axios.post(this.epUrl, 'statInfo=' + JSON.stringify({
                pathname: window.location.pathname + "--exit--",
                epGuest: this.epGuest,
                screen: window.innerWidth + "x" + window.innerHeight,
                referrer: document.referrer,
            }));
        }
        */
    },
};
</script>
