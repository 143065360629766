import axios from "axios";
import { createApp } from "vue";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;

import "./styles/quasar.scss";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/fontawesome-v6/fontawesome-v6.css";
import "./styles/ep-styles.scss";

// import { laBehanceSquare } from "@quasar/extras/line-awesome";
// import { farCircleXmark } from "@quasar/extras/fontawesome-v6";
import {
    matError,
    // matChecklist,
    // matSpellcheck,
    // matManageAccounts,
    // matCheckCircle,
    // matReportProblem,
    // matClose,
    // matSend,
    // matMic,
    // matRecordVoiceOver,
    // matList,
    // matHelpCenter,
    // matSupportAgent,
    // matLabelImportant,
    // matExtension,
    // matForum,
    // matPlayArrow,
    // matDoneOutline,
    matPerson,
    // matKeyboardArrowRight,
    // matKeyboardArrowDown,
    // matLogin,
    // matLogout,
    matAlternateEmail,
    // matContactPage,
    // matFullscreen,
} from "@quasar/extras/material-icons";

import App from "./App.vue";
// import quasarUserOptions from "./quasar-user-options";
const myIcons = {
    error: matError,
    "app:icon2": "img:/path/to/icon2.svg",
    "app:copy": "fas fa-copy",
};

const app = createApp(App)
    .use(
        Quasar,
        {
            config: {
                iconMapFn: (iconName) => {
                    // console.log(iconName);
                    const icon = myIcons[iconName];

                    return icon !== undefined
                        ? { icon }
                        : undefined;
                },
            },
            plugins: {
                Notify,
                Dialog,
            },
        },
    );

app.config.globalProperties.matError = matError;
app.config.globalProperties.matPerson = matPerson;
app.config.globalProperties.matAlternateEmail = matAlternateEmail;

window.onerror = function (message, source, lineno, colno, error) {
    const statInfo = JSON.stringify({
        epGuest: "GUEST_ERROR",
        pathname: source,
        screen: `${lineno}x${colno}`,
        referrer: `${message}(${JSON.stringify(error)})`,
    });
    axios.post(
        "/eprest/stat",
        `statInfo=${statInfo}`,
    );

    return false;
};

// app.config.globalProperties.laBehanceSquare = laBehanceSquare;
// app.config.globalProperties.farCircleXmark = farCircleXmark;

app.mount("#app");
