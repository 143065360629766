<template>
    <div class="menu-side-anchor">
        <q-menu v-model="showSideMenu">
            <q-list style="min-width: 100px">
                <q-item
                    v-for="(item, index) in context"
                    :key="index"
                    :vClosePopup="!item.items"
                    clickable
                    @click="!item.items ? openPage(item.alink) : null"
                >
                    <q-item-section
                        v-if="!item.items"
                        side
                    >
                        <i :class="item.foldr" />
                    </q-item-section>
                    <q-item-section>
                        {{ item.title }}
                    </q-item-section>
                    <q-item-section
                        v-if="item.items"
                        side
                    >
                        >
                    </q-item-section>
                    <q-menu
                        v-if="item.items"
                        autoClose
                        anchor="top end"
                        self="top start"
                    >
                        <q-list dense>
                            <q-item
                                v-for="subItem in item.items"
                                :key="subItem.title"
                                clickable
                                @click="openPage(subItem.alink)"
                            >
                                <q-item-section>
                                    {{ subItem.title }}
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-item>
            </q-list>
        </q-menu>
    </div>
</template>

<script>
export default {
    name: "SideMenu",
    props: {
        showMenu: {
            type: Number,
            required: true,
        },
        context: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showSideMenu: false,
        };
    },
    watch: {
        showMenu() {
            this.showSideMenu = true;
        },
    },
    methods: {
        openPage(pageUrl) {
            window.location = pageUrl;
        },
    },
};
</script>
